body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: '0px';
}

.login-img {
  max-height: 40px;
  max-width: 60px;
  margin: 5px;
}

.fixed-bg {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: -100;
  top: 0px;
  background-image: url('./emr-background.jpg');
  background-size: cover;
}

.coltrain-pane {
  position: fixed;
  width: 90%;
  height: 85%;
  margin-top: 80px;
  margin-left: -20px;
  z-index: 100;
  top: 0px;
  background-image: url('./coltrain-pane.jpg');
  background-size:contain;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
