.App {
  text-align: center;
}

.bar-progress {
  fill: none
}

.red-bg {
  background-color: red;
}

.orange-bg{
  background-color: orange;
}

.blue-bg {
  background-color: lightblue;
}

.gantt .bar {
  fill: none
}

.bar-milestone-red > .bar, .bar-milestone-red > .bar-group > .bar-progress
{
  fill:tomato !important
}


/* src/HospitalMap.css */
.map-container {
  display: flex;
}

.sidebar {
  width: 250px;
  padding: 10px;
  background-color: #f9f9f9;
  border-right: 1px solid #ddd;
  overflow-y: auto; /* Enables vertical scrolling */
  max-height: 100vh; /* Restricts sidebar height to viewport */
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.sidebar h3 {
  margin-top: 0;
}

.sidebar div {
  margin-bottom: 10px;
}

.sidebar label {
  margin-left: 5px;
}


.bar-milestone-yellow > .bar, .bar-milestone-yellow> .bar-group > .bar-progress
{
  fill:orange !important
}

.bar-milestone-green > .bar, .bar-milestone-green> .bar-group > .bar-progress {
 fill: green !important
}
.patientPage {
  margin-top: 8%;
  width: 80%;
  background-color: #eee;
  padding:10px
}


.patient-list-paper{
  padding:10px
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

body{
  background-color: blue;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.avatar-title {
  padding-left: 10px;
}

.timestamp{
  margin:0px;
  padding-left: 10px;
  font-weight: bold;
  font-size: x-small;
}

.feed-message{
  margin: 5px;
  padding-left: 10px;
  font-style: italic;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
